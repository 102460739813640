
import { computed, defineComponent } from "vue";
import { useRoute } from "vue-router";
import AppRouterLink from "@/components/atoms/link/AppRouterLink/AppRouterLink.vue";
import TitleSlide from "@/components/atoms/icon/TitleSlide/TitleSlide.vue";

export default defineComponent({
  components: { AppRouterLink, TitleSlide },
  props: {
    items: {
      type: Object,
      requre: true
    },
    isSolution: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const route = useRoute();
    const fullPath = computed(() => route.path);
    return {
      fullPath
    };
  }
});
