import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, normalizeClass as _normalizeClass, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-737efd09"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "nav-tab" }
const _hoisted_2 = { class: "link-text" }
const _hoisted_3 = { class: "screen-out" }
const _hoisted_4 = {
  key: 0,
  class: "screen-out"
}
const _hoisted_5 = { class: "link-text-icon" }
const _hoisted_6 = {
  key: 0,
  class: "text-base"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_TitleSlide = _resolveComponent("TitleSlide")!
  const _component_AppRouterLink = _resolveComponent("AppRouterLink")!

  return (_openBlock(), _createElementBlock("nav", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.items, (item, index) => {
      return (_openBlock(), _createBlock(_component_AppRouterLink, {
        key: index,
        url: item.path,
        class: _normalizeClass(`link${index + 1}`)
      }, {
        linkSlot: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            _createElementVNode("strong", _hoisted_3, _toDisplayString(item.title), 1),
            (!_ctx.fullPath.indexOf(item.path))
              ? (_openBlock(), _createElementBlock("span", _hoisted_4, " 선택됨 "))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_5, [
              (_ctx.isSolution)
                ? (_openBlock(), _createElementBlock("div", _hoisted_6, _toDisplayString(item.title), 1))
                : (_openBlock(), _createBlock(_component_TitleSlide, {
                    key: 1,
                    iconType: item.name,
                    class: "icon"
                  }, null, 8, ["iconType"]))
            ])
          ])
        ]),
        _: 2
      }, 1032, ["url", "class"]))
    }), 128))
  ]))
}